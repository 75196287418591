<template>
  <v-file-input accept=".csv" show-size label="File input" @change="fileChanged" hide-details></v-file-input>
</template>

<script>
export default {
  name: 'FileInput',
  props: {
    config: {
      type: Array,
      required: true,
    },
    skipRows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fileContent: '',
    };
  },
  methods: {
    fileChanged(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileContent = e.target.result;
        };
        reader.readAsText(file);
      }
    },
    handleItem(item, { split, toNumber }) {
      if (split) {
        const splitted = item.split(';');
        return toNumber ? splitted.map((item) => Number(item)) : splitted;
      }
      return toNumber ? Number(item) : item;
    },
  },
  computed: {
    items() {
      const content = this.fileContent.split('\n');
      const items = content.slice(this.skipRows).map((line) => {
        const trimmedLine = line.trim();
        if (trimmedLine.length > 0) {
          const values = trimmedLine.split(',').map((item) => item.replace(/^"|"$/g, '').trim());
          return this.config.reduce((obj, header, index) => {
            if (values[index]) {
              return { ...obj, [header.name]: this.handleItem(values[index], header) };
            } else {
              return obj;
            }
          }, {});
        }
      });
      const itemsWithoutNulls = items.filter((i) => i);
      return itemsWithoutNulls;
    },
  },
  watch: {
    items(val) {
      this.$emit('change', val);
    },
  },
};
</script>
