<template>
  <v-card flat>
    <v-card-title>{{ config.queueName }}</v-card-title>
    <v-card-subtitle>{{ config.queueId }}</v-card-subtitle>
    <v-card-text class="d-flex flex-wrap align-end">
      <div>
        <div class="d-flex">
          <name-value name="Start" :value="startTime"></name-value>
          <name-value name="End" :value="endTime"></name-value>
          <name-value name="Timezone" :value="config.timeZone"></name-value>
          <name-value name="Time Frame" :value="`${config.timeFrame} minutes`"></name-value>
        </div>
        <div>
          <name-value name="Days" :value="days"></name-value>
        </div>
        <div class="d-flex">
          <name-value name="Default Capacity" :value="config.defaultCapacity"></name-value>
          <name-value name="Return Slots" :value="config.defaultSlots"></name-value>
          <name-value name="Remove First Slots" :value="config.removeFirstSlots"></name-value>
        </div>
      </div>
      <v-spacer />
      <v-btn class="mr-2" outlined color="primary" @click="$emit('edit')" v-if="editor">Edit</v-btn>
      <v-btn class="mr-2" outlined color="error" @click="$emit('delete')" v-if="editor">Delete</v-btn>
      <v-btn depressed color="primary" :loading="loading" @click="$emit('save')" v-if="editor">Save</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import NameValue from '@/components/NameValue';
import authMixin from '@/mixins/auth';
import { weekdays } from '@/lib/klok';

export default {
  name: 'ConfigCard',
  components: {
    NameValue,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [authMixin],
  computed: {
    date() {
      const [date] = new Date().toISOString().split('T');
      return date;
    },
    startTime() {
      return new Date(`${this.date}T${this.config.startTime}`).toLocaleTimeString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    endTime() {
      return new Date(`${this.date}T${this.config.endTime}`).toLocaleTimeString('nl-NL', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    days() {
      return this.config.days.map((d) => weekdays[d]).join(', ');
    },
  },
};
</script>
