import axios from 'axios';
import store from '@/store';

const genesys = axios.create({
  baseURL: 'https://api.mypurecloud.de',
});

genesys.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      if (!error.config._retry) {
        error.config._retry = true;
        //refresh token
        console.log('refresh token');
        const auth = await store.dispatch('auth/refreshToken');
        error.config.headers.Authorization = `Bearer ${auth.genesys.access_token}`;
        //retry request
        console.log('retry request');
        return genesys(error.config);
      } else {
        console.log('logout');
        store.dispatch('auth/logout');
      }
    }
    return Promise.reject(error);
  }
);

export default genesys;
