<template>
  <v-container fluid class="grey lighten-2 fill-height">
    <v-row class="fill-height" justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title>{{ mode }} Config</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
          <v-card-text>
            <v-form v-model="valid">
              <queue-selection label="Queue" v-model="queue" :rules="rules" outlined :disabled="edit" />
              <time-picker-text-field v-model="config.startTime" label="Start Time" :rules="rules" :max="config.endTime" />
              <time-picker-text-field v-model="config.endTime" label="End Time" :rules="rules" :min="config.startTime" />
              <div class="d-flex flex-wrap">
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[0]" :value="0" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[1]" :value="1" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[2]" :value="2" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[3]" :value="3" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[4]" :value="4" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[5]" :value="5" dense></v-checkbox>
                <v-checkbox class="mr-2" v-model="config.days" :label="weekdays[6]" :value="6" dense></v-checkbox>
              </div>
              <time-zone-selection label="Timezone" v-model="config.timeZone" :rules="rules" outlined />
              <v-slider
                v-model="config.timeFrame"
                label="Time Frame"
                min="15"
                max="60"
                step="15"
                thumb-label="always"
                :readonly="edit"
              ></v-slider>
              <v-slider
                v-model="config.defaultCapacity"
                label="Default Capacity"
                thumb-label="always"
                :readonly="edit"
              ></v-slider>
              <v-slider v-model="days" min="1" max="14" label="Return Slots (days)" thumb-label="always"></v-slider>
              <v-slider v-model="config.removeFirstSlots" label="Remove First Slots" max="10" thumb-label="always"></v-slider>
              <v-btn color="primary" :disabled="!valid" @click="$emit('change')">{{ buttonText }}</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QueueSelection from '@/components/QueueSelection';
import TimePickerTextField from '@/components/TimePickerTextField';
import TimeZoneSelection from '@/components/TimeZoneSelection';
import { weekdays } from '@/lib/klok';

export default {
  name: 'ConfigAddEdit',
  components: {
    QueueSelection,
    TimePickerTextField,
    TimeZoneSelection,
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: function (v) {
        return ['Add', 'Edit'].includes(v);
      },
    },
    buttonText: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
  },
  data: () => ({
    valid: false,
    rules: [(v) => !!v || 'This field is required'],
    weekdays,
  }),
  computed: {
    config: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    subtitle() {
      if (this.mode === 'Add') return 'Add new queue to callback manager';
      if (this.mode === 'Edit') return 'Edit existing queue';
      return '';
    },
    edit() {
      return this.mode === 'Edit';
    },
    days: {
      get() {
        return this.config.defaultSlots / (1440 / this.config.timeFrame);
      },
      set(v) {
        this.config.defaultSlots = (1440 / this.config.timeFrame) * v;
      },
    },
    queue: {
      get() {
        return {
          id: this.config.queueId,
          name: this.config.queueName,
        };
      },
      set(val) {
        this.config.queueId = val.id;
        this.config.queueName = val.name;
      },
    },
  },
};
</script>
