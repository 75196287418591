import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('genesys/me', ['hasRole']),
    editor() {
      return this.hasRole('Callback Manager Editor');
    },
  },
};
