<template>
  <v-app>
    <app-bar @drawer="drawer = !drawer"></app-bar>
    <navigation-drawer v-model="drawer" v-if="loggedIn" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBar from '@/components/AppBar';
import NavigationDrawer from '@/components/NavigationDrawer';

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
