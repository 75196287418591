import genesys from '@/axios/genesys';

export default {
  getUri(uri) {
    return genesys.get(uri);
  },
  getMe() {
    return genesys.get('/api/v2/users/me?expand=organization%2Cauthorization');
  },
  getQueues(name) {
    return genesys.get(`/api/v2/routing/queues${name ? '?name=*' + name + '*' : ''}`);
  },
};
