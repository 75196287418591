<template>
  <div>
    <configs-table @click="handleConfigClick" />
    <action-button bottom right fixed @click="handleAddClick" v-if="editor">
      <v-icon>mdi-plus</v-icon>
    </action-button>
  </div>
</template>

<script>
import ConfigsTable from '../components/ConfigsTable';
import ActionButton from '../components/ActionButton';
import authMixin from '@/mixins/auth';

export default {
  name: 'ConfigsView',
  components: {
    ConfigsTable,
    ActionButton,
  },
  mixins: [authMixin],
  methods: {
    handleConfigClick(config) {
      this.$router.push({ name: 'configdetail', params: { id: config.id } });
    },
    handleAddClick() {
      this.$router.push({ name: 'configadd' });
    },
  },
};
</script>
