import GenesysService from '@/services/GenesysService';

export default {
  namespaced: true,
  state: {
    me: null,
  },
  mutations: {
    SET_ME(state, me) {
      state.me = me;
    },
  },
  actions: {
    async getMe({ commit }) {
      try {
        const { data } = await GenesysService.getMe();
        delete data.authorization.permissionPolicies;
        commit('SET_ME', data);
      } catch (error) {
        console.error(error.message);
      }
    },
  },
  getters: {
    hasRole: (state) => (role) => {
      const index = state.me?.authorization.roles.findIndex((r) => r.name.toLowerCase() === role.toLowerCase());
      return index !== -1;
    },
  },
};
