<template>
  <config-add-edit v-if="config" mode="Edit" buttonText="Save" v-model="config" @change="handleChange()" />
</template>

<script>
import ConfigAddEdit from '@/components/ConfigAddEdit.vue';
import KpnService from '@/services/KpnService';

export default {
  name: 'ConfigEditView',
  props: ['id'],
  components: {
    ConfigAddEdit,
  },
  data() {
    return {
      config: null,
    };
  },
  created() {
    this.getConfig(this.id);
  },
  methods: {
    handleChange() {
      this.editConfig(this.config);
    },
    async editConfig(config) {
      await KpnService.editConfig(this.id, config);
      this.$router.push({ name: 'configdetail', params: { id: this.id } });
    },
    async getConfig(id) {
      const response = await KpnService.getConfig(id);
      this.config = response.data;
    },
  },
};
</script>
