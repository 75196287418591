import axios from 'axios';
import store from '@/store';

const kpn = axios.create({
  baseURL: process.env.VUE_APP_KPN_API_URI,
});

kpn.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      if (!error.config._retry) {
        error.config._retry = true;
        //refresh token
        console.log('refresh token');
        const auth = await store.dispatch('auth/refreshToken');
        error.config.headers.Authorization = `Bearer ${auth.kpn.access_token}`;
        //retry request
        console.log('retry request');
        return kpn(error.config);
      } else {
        console.log('logout');
        store.dispatch('auth/logout');
      }
    }
    return Promise.reject(error);
  }
);

export default kpn;
