<template>
  <v-list-item v-if="me">
    <v-list-item-avatar>
      <img :src="me.images[0].imageUri" :alt="me.name" v-if="me.images" />
      <img src="https://dhqbrvplips7x.cloudfront.net/directory/10.21.0-1/assets/images/svg/person.svg" v-else />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ me.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ me.organization.name }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'MeListItem',
  created() {
    this.$store.dispatch('genesys/me/getMe');
  },
  computed: {
    ...mapState('genesys/me', ['me']),
  },
};
</script>
