<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-progress-circular v-if="loading" :size="75" indeterminate></v-progress-circular>
      <span v-else>
        <strong class="red--text">{{ message }}</strong>
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CallbackView',
  data() {
    return {
      loading: true,
      message: '',
    };
  },
  created() {
    const code = this.$route.query.code;
    const redirectUri = process.env.VUE_APP_REDIRECT_URI;
    if (code) {
      this.$store
        .dispatch('auth/getToken', { code, redirectUri })
        .then(() => {
          this.$router.push('/');
        })
        .catch((error) => {
          this.message = error?.response?.data?.message || 'something went wrong';
          console.error(error);
        })
        .finally(() => (this.loading = false));
    }
  },
};
</script>
