<template>
  <v-data-table :headers="headers" :items="items" :loading="items.length === 0">
    <template v-slot:[`item.queueName`]="{ item }">
      <span class="pointer primary--text" @click="$emit('click', item)">{{ item.queueName }}</span>
    </template>
  </v-data-table>
</template>

<script>
import KpnService from '@/services/KpnService';

export default {
  name: 'ConfigsTable',
  data: () => ({
    headers: [
      {
        text: 'Queue',
        value: 'queueName',
      },
    ],
    items: [],
  }),
  created() {
    this.getConfigs();
  },
  methods: {
    async getConfigs() {
      const response = await KpnService.getConfigs();
      this.items = response.data;
    },
  },
};
</script>
