import kpn from '@/axios/kpn';

export default {
  getConfigs() {
    return kpn.get('/cbmanager/configs');
  },
  getConfig(id) {
    return kpn.get(`/cbmanager/configs/${id}`);
  },
  getConfigSlots(id, start, end) {
    return kpn.get(`/cbmanager/configs/${id}/slots?start=${start}&end=${end}`);
  },
  updateConfigSlots(id, slots) {
    return kpn.patch(`/cbmanager/configs/${id}/slots`, slots);
  },
  importConfigSlots(slots) {
    return kpn.patch(`/cbmanager/configs/slots`, slots);
  },
  addConfig(body) {
    return kpn.post(`/cbmanager/configs`, body);
  },
  deleteConfig(id) {
    return kpn.delete(`/cbmanager/configs/${id}`);
  },
  editConfig(id, body) {
    return kpn.put(`/cbmanager/configs/${id}`, body);
  },
};
