<template>
  <div v-if="config">
    <config-card :config="config" :loading="saving" @save="updateConfigSlots()" @delete="deleteConfig()" @edit="editConfig()" />
    <week-range :config="config" @update="handleRangeUpdate" />
    <slots-table :items="slots" :loading="loading" :config="config" @change="handleSlotsChange" />
  </div>
</template>

<script>
import KpnService from '@/services/KpnService';
import ConfigCard from '@/components/ConfigCard';
import SlotsTable from '@/components/SlotsTable';
import WeekRange from '@/components/WeekRange';

export default {
  name: 'ConfigDetailView',
  props: ['id'],
  components: { ConfigCard, SlotsTable, WeekRange },
  data: () => ({
    config: null,
    slots: [],
    loading: false,
    saving: false,
  }),
  created() {
    this.getConfig(this.id);
  },
  methods: {
    async getConfig(id) {
      const response = await KpnService.getConfig(id);
      this.config = response.data;
    },
    async updateConfigSlots() {
      this.saving = true;
      const body = this.slots.map((s) => ({ slot: s.slot, capacity: s.capacity }));
      await KpnService.updateConfigSlots(this.config.id, body);
      this.saving = false;
    },
    async deleteConfig() {
      if (confirm('Are you sure?')) {
        await KpnService.deleteConfig(this.config.id);
        this.$router.push({ name: 'configs' });
      }
    },
    editConfig() {
      this.$router.push({ name: 'configedit' });
    },
    async handleRangeUpdate(range) {
      this.loading = true;
      const response = await KpnService.getConfigSlots(this.id, range.start, range.end);
      this.slots = response.data;
      this.loading = false;
    },
    handleSlotsChange(slots) {
      this.slots = slots;
    },
  },
};
</script>
