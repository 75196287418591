export const getTimeZoneDiffInMinutes = (date, timeZone) => {
  const d = new Date(date);
  const local = new Date(d.toLocaleString('en-US', { timeZone: 'UTC' }));
  const remote = new Date(d.toLocaleString('en-US', { timeZone }));
  const diff = (remote - local) / 1000 / 60;
  return diff;
};

export const generateTimeZoneOffset = (offset) => {
  const a = offset < 0 ? '-' : '+';
  const b = Math.abs(Math.trunc(offset / 60))
    .toString()
    .padStart(2, '0');
  const c = Math.abs(offset % 60)
    .toString()
    .padStart(2, '0');
  return a + b + c;
};

export const getISOString = (date, timeZone) => {
  const diff = getTimeZoneDiffInMinutes(date, timeZone);
  const offset = generateTimeZoneOffset(diff);
  const d = new Date(date.toLocaleString('en-US') + offset);
  return d.toISOString();
};

export const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
