<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="time" :label="label" outlined readonly v-bind="attrs" v-on="on" :rules="rules"></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      format="24hr"
      :allowed-minutes="allowedStep"
      @click:minute="$refs.menu.save(time)"
      :min="min"
      :max="max"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'TimePickerTextField',
  data() {
    return {
      menu: false,
    };
  },
  props: {
    label: { type: String },
    rules: { type: Array },
    min: { type: String },
    max: { type: String },
    value: { type: String },
  },
  computed: {
    time: {
      get() {
        const timeWithSeconds = this.value;
        const [hours, minutes] = timeWithSeconds.split(':');
        return `${hours}:${minutes}`;
      },
      set(v) {
        const timeWithSeconds = `${v}:00`;
        this.$emit('input', timeWithSeconds);
      },
    },
  },
  methods: {
    allowedStep(m) {
      return m % 5 === 0;
    },
  },
};
</script>
