<template>
  <config-add-edit mode="Add" buttonText="Add" v-model="config" @change="handleChange()" />
</template>

<script>
import ConfigAddEdit from '@/components/ConfigAddEdit.vue';
import KpnService from '@/services/KpnService';

export default {
  name: 'ConfigAddView',
  components: {
    ConfigAddEdit,
  },
  data() {
    return {
      config: {
        queueId: null,
        queueName: null,
        startTime: '09:00:00',
        endTime: '17:00:00',
        days: [0, 1, 2, 3, 4, 5, 6],
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeFrame: 30,
        defaultCapacity: 20,
        defaultSlots: null,
        removeFirstSlots: 0,
      },
    };
  },
  methods: {
    handleChange() {
      this.addConfig(this.config);
    },
    async addConfig(config) {
      await KpnService.addConfig(config);
      this.$router.push({ name: 'configs' });
    },
  },
};
</script>
