<template>
  <v-card flat>
    <v-card-text>
      <v-btn class="mr-2" outlined small @click="today">this week</v-btn>
      <v-btn class="mr-2" icon @click="previous"><v-icon>mdi-chevron-left</v-icon></v-btn>
      <span>{{ start().toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</span>
      <span> - </span>
      <span>{{ end().toLocaleDateString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</span>
      <v-btn class="ml-2" icon @click="next"><v-icon>mdi-chevron-right</v-icon></v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from 'lodash/debounce';
import { getISOString } from '@/lib/klok';

export default {
  name: 'WeekRange',
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    currentDate: null,
  }),
  created() {
    this.today();
  },
  computed: {
    dayOfMonth() {
      return this.currentDate.getDate();
    },
  },
  methods: {
    start() {
      const d = new Date(this.currentDate);
      const diff = this.currentDate.getDate() - this.currentDate.getDay();
      d.setDate(diff);
      return d;
    },
    end() {
      const d = new Date(this.currentDate);
      const diff = this.currentDate.getDate() - this.currentDate.getDay() + 7;
      d.setDate(diff);
      const x = new Date(d.getTime() - 1);
      return x;
    },
    next() {
      const d = new Date(this.currentDate);
      d.setDate(this.dayOfMonth + 7);
      this.currentDate = d;
      this.handleRangeChange();
    },
    previous() {
      const d = new Date(this.currentDate);
      d.setDate(this.dayOfMonth - 7);
      this.currentDate = d;
      this.handleRangeChange();
    },
    today() {
      this.currentDate = this.newDate();
      this.sendEvent();
    },
    newDate() {
      const d = new Date();
      d.setHours(0, 0, 0, 0);
      return d;
    },
    handleRangeChange: debounce(function () {
      this.sendEvent();
    }, 500),
    sendEvent() {
      const start = getISOString(this.start(), this.config.timeZone);
      const end = getISOString(this.end(), this.config.timeZone);
      this.$emit('update', { start, end });
    },
  },
};
</script>
