<template>
  <div>
    <div class="d-flex align-end pa-4">
      <FileInput class="mr-4" :config="config" @change="handleFileChanged" />
      <v-btn color="primary" depressed :disabled="disabled" :loading="saving" @click="updateConfigSlots()">Save</v-btn>
    </div>
    <v-data-table :headers="headers" :items="modifiedItems">
      <template v-slot:[`item.error`]="{ item }">
        <v-icon v-if="item.error" color="red">mdi-alert-circle-outline</v-icon>
      </template>
    </v-data-table>
    <div class="pa-4 warning--text" v-if="messages.length > 0">
      <p v-for="(message, i) in messages" :key="i">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput';
import KpnService from '@/services/KpnService';

export default {
  name: 'ConfigImportView',
  components: {
    FileInput,
  },
  data() {
    return {
      items: [],
      config: [{ name: 'queueName' }, { name: 'slot' }, { name: 'capacity', toNumber: true }],
      headers: [
        { text: 'Queue Name', value: 'queueName' },
        { text: 'Slot (UTC)', value: 'slot' },
        { text: 'Capacity', value: 'capacity' },
        { value: 'error' },
      ],
      saving: false,
      messages: [],
    };
  },
  computed: {
    disabled() {
      return !this.verifiedItems.length > 0 || !this.verifiedItems.every((i) => !i.error);
    },
    itemsWithDate() {
      return this.items.map((i) => ({
        queueName: i.queueName,
        slot: new Date(i.slot) > new Date() ? new Date(i.slot) : 'slot must be in the future',
        capacity: i.capacity,
      }));
    },
    verifiedItems() {
      return this.itemsWithDate.map((i) => {
        return typeof i.queueName === 'string' && typeof i.slot === 'object' && i.capacity >= 0
          ? { ...i, error: false }
          : { ...i, error: true };
      });
    },
    modifiedItems() {
      return this.verifiedItems.map((i) => ({
        ...i,
        slot: typeof i.slot === 'object' ? i.slot.toISOString() : i.slot,
      }));
    },
  },
  methods: {
    handleFileChanged(items) {
      this.items = items;
    },
    async updateConfigSlots() {
      this.saving = true;
      const body = this.modifiedItems.map((s) => ({ queueName: s.queueName, slot: s.slot, capacity: s.capacity }));
      const { data } = await KpnService.importConfigSlots(body);
      this.messages = data.messages || [];
      this.saving = false;
    },
  },
};
</script>
