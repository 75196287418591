<template>
  <v-data-table
    :headers="headers"
    :items="groupedItems"
    :loading="loading"
    disable-pagination
    hide-default-footer
    hide-default-header
  >
    <template v-slot:header>
      <thead>
        <tr>
          <th v-for="(header, i) in headers" :key="i">
            <div v-if="header.value !== 'time'" class="d-flex">
              <div class="text-center">
                <div>{{ header.text }}</div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <td v-for="(header, i) in headers" :key="i">
          <template v-if="header.value === 'time'">{{ item[header.value] }}</template>
          <template v-else>
            <span class="mr-1 font-weight-bold">{{ item[header.value]?.capacity }}</span>
            <span
              class="mr-1"
              v-bind:class="[item[header.value]?.capacity > item[header.value]?.planned ? 'green--text' : 'red--text']"
            >
              {{ item[header.value]?.planned }}
            </span>
            <v-icon dense v-if="editor" @click="editItem(item[header.value]?.slot, 'increase')">mdi-arrow-up-thin</v-icon>
            <v-icon
              dense
              v-if="editor"
              @click="if (item[header.value]?.capacity > 0) editItem(item[header.value]?.slot, 'decrease');"
            >
              mdi-arrow-down-thin
            </v-icon>
          </template>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import authMixin from '@/mixins/auth';

export default {
  name: 'SlotsTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  mixins: [authMixin],
  computed: {
    headers() {
      if (this.groupedItems.length === 0) return [];
      return Object.keys(this.groupedItems[0]).map((k) => ({ text: k, value: k, sortable: false }));
    },
    groupedItems() {
      return this.items.reduce((p, c) => {
        const date = new Date(c.slot).toLocaleDateString('nl-NL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          timeZone: this.config.timeZone,
        });
        const time = new Date(c.slot).toLocaleTimeString('nl-NL', {
          hour: '2-digit',
          minute: '2-digit',
          timeZone: this.config.timeZone,
        });
        const index = p.findIndex((x) => x.time === time);
        if (index >= 0) {
          p[index] = { ...p[index], [date]: { slot: c.slot, capacity: c.capacity, planned: c.planned } };
          return p;
        }
        return [...p, { time, [date]: { slot: c.slot, capacity: c.capacity, planned: c.planned } }];
      }, []);
    },
  },
  methods: {
    editItem(slot, operator) {
      const updatedItems = this.items.map((i) => {
        if (i.slot === slot) {
          return { ...i, capacity: operator === 'increase' ? i.capacity + 1 : i.capacity - 1 };
        }
        return i;
      });
      this.$emit('change', updatedItems);
    },
  },
};
</script>
