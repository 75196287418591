import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import CallbackView from '../views/CallbackView.vue';
import ConfigsView from '../views/ConfigsView.vue';
import LoginView from '../views/LoginView.vue';
import ConfigDetailView from '../views/ConfigDetailView.vue';
import ConfigAddView from '../views/ConfigAddView.vue';
import ConfigEditView from '../views/ConfigEditView.vue';
import ConfigImportView from '../views/ConfigImportView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'configs' },
  },
  {
    path: '/callback',
    name: 'callback',
    component: CallbackView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/configs',
    name: 'configs',
    component: ConfigsView,
    meta: { requiresAuth: true },
  },
  {
    path: '/configs/:id',
    name: 'configdetail',
    component: ConfigDetailView,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/configs/import',
    name: 'configimport',
    component: ConfigImportView,
    meta: { requiresAuth: true },
  },
  {
    path: '/configs/add',
    name: 'configadd',
    component: ConfigAddView,
    meta: { requiresAuth: true },
  },
  {
    path: '/configs/:id/edit',
    name: 'configedit',
    component: ConfigEditView,
    props: true,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];
  if (to.meta.requiresAuth) {
    if (!loggedIn) next({ name: 'login' });
    else next();
  } else {
    next();
  }
});

export default router;
