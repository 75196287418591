<template>
  <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="white" elevation="2">
    <v-app-bar-nav-icon @click="handleNavIconClick()" />
    <v-toolbar-title>KPN Callback Manager</v-toolbar-title>
    <v-spacer />
    <LoginLogoutButton />
  </v-app-bar>
</template>

<script>
import LoginLogoutButton from '@/components/LoginLogoutButton';

export default {
  name: 'AppBar',
  components: {
    LoginLogoutButton,
  },
  methods: {
    handleNavIconClick() {
      this.$emit('drawer');
    },
  },
};
</script>
