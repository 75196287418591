<template>
  <v-container fluid class="text-center">
    <p class="text-h5">Login</p>
    <v-btn color="deep-orange" outlined @click="login()">sign in with genesys cloud</v-btn>
  </v-container>
</template>

<script>
import redirect from '@/lib/redirect';

export default {
  name: 'LoginView',
  methods: {
    login() {
      redirect();
    },
  },
};
</script>
