<template>
  <div class="mr-2">
    <span class="font-weight-bold">{{ name }}:</span> {{ value }}
  </div>
</template>

<script>
export default {
  name: 'NameValue',
  props: ['name', 'value'],
};
</script>
