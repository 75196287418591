<template>
  <v-autocomplete v-bind="$attrs" v-on="$listeners" v-model="timeZone" :items="zones"></v-autocomplete>
</template>

<script>
export default {
  name: 'TimeZoneSelection',
  props: {
    value: { type: String },
  },
  data: () => ({
    zones: Intl.supportedValuesOf('timeZone'),
  }),
  computed: {
    timeZone: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
