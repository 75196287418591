<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="queue"
    :loading="loading"
    :items="queues"
    :search-input.sync="search"
    cache-items
    item-text="name"
    return-object
  ></v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce';
import GenesysService from '@/services/GenesysService';

export default {
  name: 'QueueSelection',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    search: null,
    queues: [],
    loading: false,
  }),
  created() {
    this.searchQueues();
  },
  computed: {
    queue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  watch: {
    search(val) {
      val && val !== this.queue?.name && this.handleSearchChange(val);
    },
  },
  methods: {
    handleSearchChange: debounce(function (val) {
      this.searchQueues(val);
    }, 500),
    async searchQueues(search) {
      this.loading = true;
      try {
        const response = await GenesysService.getQueues(search);
        this.queues = response.data.entities;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
